export const appleLogo = require("../assets/appleMusic.png");
export const spotifyLogo = require("../assets/spotify.png");
export const youtubeLogo = require("../assets/youtube-white.png");

export const cover = require("../assets/poster.png");
export const logo = require("../assets/logo.png");
export const title = require("../assets/title.png");

export const lifeInABoxCover = require("../assets/poster.png");
export const furtherCover = require("../assets/further.png");
